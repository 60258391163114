import React from 'react';
// https://dev.to/envoy_/150-badges-for-github-pnk

export default function PodcastBadges(props) {
	return (
		<div>
			<a href="https://podcasts.apple.com/us/podcast/so-you-can-hear-me-when-im-gone/id1546289413">
				<img
					src="https://img.shields.io/badge/Apple_Podcasts-9933CC?style=for-the-badge&logo=apple-podcasts&logoColor=white"
					alt="apple podcasts"></img>
			</a>
			{/* <img src="https://img.shields.io/badge/Google_Podcasts-4285F4?style=for-the-badge&logo=google-podcasts&logoColor=white" alt="google podcasts"></img> */}
			<a href="https://open.spotify.com/show/5YrcUfdkvJh813ODBH85dC">
				<img
					src="https://img.shields.io/badge/Spotify-1ED760?&style=for-the-badge&logo=spotify&logoColor=white"
					alt="spotify podcasts"></img>
			</a>
			<p>
				<a href={props.feed}>
					<img
						src="https://img.shields.io/badge/RSS-Feed-orange"
						alt="rss feed xml"></img>
				</a>
			</p>
			<p></p>
		</div>
	);
}
