import logo from './heart.png';
import './App.css';
import EpisodeList from './components/EpisodeList';
import PodcastBadges from './components/PodcastBadges';
import axios from 'axios';
import React, { Component } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Container } from '@mui/material';
let parser = require('fast-xml-parser');

const soyoFeedUrl =
	'https://soyoucanhearmewhenimgone.s3.us-west-2.amazonaws.com/feed.xml';

class App extends Component {
	state = {
		episodes: [],
	};

	componentDidMount() {
		axios.get(soyoFeedUrl).then(response => {
			let data = parser.parse(response.data);
			this.setState({ episodes: data.rss.channel.item.reverse() });
		});
	}

	render() {
		return (
			<div className="App">
				<Container maxWidth="sm">
					<header className="App-header">
						<h1>So You Can Hear Me When I'm Gone</h1>
						<img src={logo} className="App-logo" alt="logo" />
						<p> </p>
						<PodcastBadges feed={soyoFeedUrl} />
						<EpisodeList episodes={this.state.episodes} />
					</header>
				</Container>
			</div>
		);
	}
}

export default App;
