import React from 'react';
import Episode from './Episode';
import './EpisodeList.css'

export default function EpisodeList(props) {
	if (props.episodes === null) return <p></p>;
	if (props.episodes === undefined) return <p></p>;
	if (props.episodes === []) return <p></p>;
	if (props.episodes.length === 0) return <p></p>;
	return (
		<div>
			<h1>Episodes</h1>
			<div className="left-justify">
				{props.episodes.map(episode => {
					return <Episode title={episode['itunes:title']} url={episode.guid} date={episode.pubDate} descr={episode['itunes:summary']} />;
				})}
			</div>
		</div>
	);
}

//   episodes: [
//     {
//       title: "S01E01 - Doreen Perry",
//       "itunes:title": "Doreen Perry",
//       "itunes:season": 1,
//       "itunes:episode": 1,
//       "itunes:author": "Dallin Perry",
//       "itunes:subtitle":
//         'First episode! Kicking things off is our mother dear, Doreen Perry. We discuss life growing up as a military brat,  the "Hermiston House" in San Jose, CA, earning her father’s trust with driving privileges, applying to and attending BYU with some culture shock, the road trip to Butte Montana, and much more.',
//       "itunes:summary":
//         'First episode! Kicking things off is our mother dear, Doreen Perry. We discuss life growing up as a military brat,  the "Hermiston House" in San Jose, CA, earning her father’s trust with driving privileges, applying to and attending BYU with some culture shock, the road trip to Butte Montana, and much more.',
//       "itunes:image": "",
//       enclosure: "",
//       guid: "https://soyoucanhearmewhenimgone.s3-us-west-2.amazonaws.com/001/1-DoreenPerry.mp3",
//       pubDate: "10 Dec 2020 18:44:43 -0700",
//       "itunes:duration": "01:27:38",
//       "itunes:explicit": "no",
//     },
//     {
//       title: "S01E02 - Danial Perry",
//       "itunes:title": "Danial Perry",
//       "itunes:season": 1,
//       "itunes:episode": 2,
//       "itunes:author": "Dallin Perry",
//       "itunes:subtitle":
//         "We discuss Dan's scouting career, mud slinging in Toketee Falls, Canoeing at Camp Baker, Leadership in scouting, Values learned, and much more.",
//       "itunes:summary":
//         "We discuss Dan's scouting career, mud slinging in Toketee Falls, Canoeing at Camp Baker, Leadership in scouting, Values learned, and much more.",
//       "itunes:image": "",
//       enclosure: "",
//       guid: "https://soyoucanhearmewhenimgone.s3-us-west-2.amazonaws.com/002/2-DanialPerry.mp3",
//       pubDate: "20 Dec 2020 20:30:00 -0700",
//       "itunes:duration": "01:06:36",
//       "itunes:explicit": "no",
//     },
//   ],
