import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import dayjs from 'dayjs';
let customParseFormat = require('dayjs/plugin/customParseFormat');
let relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export default function Episode(props) {
	let timeAgoStyle = {
		fontSize: '55%',
	};

	let descriptionStyle = {
		fontSize: '55%',
		alignItems: 'center',
	};

	return (
		<div>
			<p></p>
			<AudioPlayer
				src={props.url}
				layout="stacked"
				header={
					<div>
						<h4>
							{props.title}{' '}
							<span style={timeAgoStyle}>
								({dayjs(props.date, 'DD MMM YYYY HH:mm:ss ZZ').from()})
							</span>
						</h4>
						<p style={descriptionStyle}>{props.descr}</p>
					</div>
				}
			/>
			<p></p>
		</div>
	);
}
